<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              :name="beForm[rep].insurance_policy_id.label"
                              :label="beForm[rep].insurance_policy_id.label"
                              v-model="form.inpt_label"
                              :rules="getRules('insurance_policy_id')"
                              :readonly="true"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchPolicy"
                              :disabled="true"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <span
                        v-if="insurance_policy_data"
                        class="info"
                        v-html="
                          toInfoData(
                            insurance_policy_data,
                            'insurance_policy',
                            0
                          )
                        "
                      >
                      </span>
                    </div>
                    <quick-search-policy-modal
                      ref="quickSearchPolicy"
                      @input="handlePolicyModalInput"
                    ></quick-search-policy-modal>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].code.label"
                        vid="code"
                        :label="beForm[rep].code.label"
                        v-model="form[rep].code"
                        :options="code_options"
                        :rules="getRules('code')"
                        :disabled="
                          [0, 1, 2, 3, 4, 5, 6].includes(
                            beForm[rep].status_ancillary.value
                          )
                        "
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].status_ancillary.label"
                        vid="status_ancillary"
                        :label="beForm[rep].status_ancillary.label"
                        v-model="form[rep].status_ancillary"
                        :options="beForm[rep].status_ancillary.options"
                        :rules="getRules('status_ancillary')"
                        :disabled="
                          disabled ||
                          [4, 5, 6].includes(beForm[rep].status_ancillary.value)
                        "
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="effective_at"
                        :name="beForm[rep].effective_at.label"
                        :label="beForm[rep].effective_at.label"
                        v-model="form[rep].effective_at"
                        :rules="getRules('effective_at')"
                        :readonly="
                          [0, 1, 2, 3, 4, 5, 6].includes(
                            beForm[rep].status_ancillary.value
                          )
                        "
                        :disabled="
                          [0, 1, 2, 3, 4, 5, 6].includes(
                            beForm[rep].status_ancillary.value
                          )
                        "
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="delay_days"
                        :name="beForm[rep].delay_days.label"
                        :label="beForm[rep].delay_days.label"
                        v-model="form[rep].delay_days"
                        :options="beForm[rep].delay_days.options"
                        :rules="getRules('delay_days')"
                        :disabled="
                          [0, 1, 2, 3, 4, 5, 6].includes(
                            beForm[rep].status_ancillary.value
                          ) || beForm[rep].code.value === 'A'
                            ? true
                            : false
                        "
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].broker_id.label"
                        vid="broker_id"
                        :label="beForm[rep].broker_id.label"
                        v-model="form[rep].broker_id"
                        :options="salesmen"
                        :rules="getRules('broker_id')"
                        :disabled="
                          [0, 1, 2, 3, 4, 5, 6].includes(
                            beForm[rep].status_ancillary.value
                          )
                        "
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Premio" header-tag="header" class="mt-2">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].net.label"
                        vid="net"
                        :label="beForm[rep].net.label"
                        v-model="form[rep].net"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :rules="getRules('net')"
                        :disabled="
                          [1, 2, 3, 4, 5, 6].includes(
                            beForm[rep].status_ancillary.value
                          )
                        "
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].tax.label"
                        vid="tax"
                        :label="beForm[rep].tax.label"
                        v-model="form[rep].tax"
                        :rules="getRules('tax')"
                        :options="{ currency: 'EUR', locale: 'it-IT' }"
                        :disabled="
                          [1, 2, 3, 4, 5, 6].includes(
                            beForm[rep].status_ancillary.value
                          )
                        "
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].gross.label"
                        vid="gross"
                        :label="beForm[rep].gross.label"
                        v-model="grossPremiumSum"
                        :readonly="true"
                        custom_type="currency"
                        :rules="getRules('gross')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                v-if="agencyHasFee"
                header="Diritti"
                header-tag="header"
                class="mt-2"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-currency
                        name="fee_net"
                        vid="fee_net"
                        label="Netto"
                        v-model="form[rep].fee_net"
                        :readonly="
                          [1, 2, 3, 4, 5, 6].includes(
                            beForm[rep].status_ancillary.value
                          ) || !insurerHasFee
                        "
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        name="fee_tax"
                        vid="fee_tax"
                        label="Tasse"
                        v-model="form[rep].fee_tax"
                        :readonly="
                          [1, 2, 3, 4, 5, 6].includes(
                            beForm[rep].status_ancillary.value
                          ) || !insurerHasFee
                        "
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        name="fee_gross"
                        vid="fee_gross"
                        label="Lordo"
                        v-model="grossSumFee"
                        readonly
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                v-if="isDelegaNostra && isPorQ && !isDeposited"
                header="Coass"
                header-tag="header"
                class="mt-2"
              >
                <b-card-text>
                  <!-- se importato o in stato diverso DA INCASSARE questa tabella è in sola lettura. -->
                  <b-table
                    striped
                    hover
                    :items="insurerParticipants"
                    :fields="insurerParticipantsFields"
                  >
                    <template v-slot:cell(compagnia_delegata)="row">
                      {{ row.item.code_importer }} -
                      {{ row.item.title_importer }}
                    </template>
                    <template v-slot:cell(agenzia_delegata)="row">
                      {{ row.item.code }}
                    </template>
                    <template v-slot:cell(descrizione)="row">
                      {{ row.item.title }}
                    </template>
                    <template v-slot:cell(participant_prov_purchase)="row">
                      {{
                        toLocaleCurrency(
                          row.item.pivot.participant_prov_purchase
                        )
                      }}
                    </template>
                    <template v-slot:cell(participant_prov_take)="row">
                      {{
                        toLocaleCurrency(row.item.pivot.participant_prov_take)
                      }}
                    </template>
                    <template v-slot:cell(net)="row">
                      <base-currency
                        vid="net"
                        name="net"
                        label="Netto"
                        v-model="insurerParticipantsForm[row.item.id].net"
                        :options="{
                          locale: 'it-IT',
                          currency: 'EUR',
                          precision: 2,
                        }"
                        :readonly="isImported || !isDaIncassare"
                      />
                    </template>
                    <!-- <template
                      v-slot:cell(tax)="row"
                      v-if="isImported || !isDaIncassare"
                    >
                      {{ toLocaleCurrency(row.item.pivot.tax) }}
                    </template> -->
                    <template v-slot:cell(tax)="row">
                      <base-currency
                        vid="tax"
                        name="tax"
                        label="Tasse"
                        v-model="insurerParticipantsForm[row.item.id].tax"
                        :options="{
                          locale: 'it-IT',
                          currency: 'EUR',
                          precision: 2,
                        }"
                        :readonly="isImported || !isDaIncassare"
                      />
                    </template>
                    <template v-slot:cell(gross)="row">
                      {{
                        insurerParticipantsForm[row.item.id].tax &&
                        insurerParticipantsForm[row.item.id].net
                          ? toLocaleCurrency(insPrtcGrss(row.item.id))
                          : toLocaleCurrency(
                              insurerParticipantsForm[row.item.id].gross
                            )
                      }}
                      <!-- {{ insurerParticipantsForm[row.item.id].gross }} -->
                    </template>
                  </b-table>
                </b-card-text>
              </b-card>
              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <custom-inputs
                      :customInputs="customInputs[rep]"
                      v-model="form[rep]"
                      :beForm="beForm[rep]"
                      :beRules="beRules[rep]"
                    />
                  </b-card-text>
                </b-card>
              </div>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseSelect from "@/components/form/BaseSelect";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import CustomInputs from "@/components/form/CustomInputs";
import BaseCurrency from "@/components/form/BaseCurrency";
import QuickSearchPolicyModal from "@/components/modals/quickSearchPolicy";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { toInfoData } from "@/utils/transforms";
import { mapGetters } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import client from "@/repositories/api";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "insurance_ancillary",
      id: this.$route.params.id,
      insurance_policy_data: null,
      code_options: [],
      salesman: [],
      disabled: false,
      insurerHasFee: false,
      form: {
        quick_value: "",
        inpt_label: "",
        insurance_ancillary: {
          title: null,
          code: "A",
          effective_at: null,
          delay_days: null,
          insurance_policy_id: null,
          salesman_id: null,
          status_ancillary: null,
          net: null,
          gross: null,
          tax: null,
        },
      },
      insurerParticipantsFields: [
        // { key: "id", label: "ID" },
        { key: "compagnia_delegata", label: "Codice Compagnia" },
        { key: "agenzia_delegata", label: "Codice Agenzia" },
        { key: "descrizione", label: "Descrizione" },
        {
          key: "participant_prov_purchase",
          label: "Provv.Acq.Agente",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "participant_prov_take",
          label: "Provv.Inc.Agente",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "net",
          label: "Imponibile",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "tax",
          label: "Imposte",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "gross",
          label: "Totale",
          thClass: "text-right",
          tdClass: "text-right",
        },
      ],
      ancillaryData: [],
      insurerParticipants: [],
      insurerParticipantsForm: {},
    };
  },
  components: {
    BaseIcon,
    BaseSelect,
    BaseInput,
    CustomInputs,
    BaseCurrency,
    BaseDatepicker,
    QuickSearchPolicyModal,
  },
  methods: {
    toInfoData,
    toLocaleCurrency,
    onSubmit() {
      this.isLoading = true;
      this.form[this.rep].gross =
        this.form[this.rep].net + this.form[this.rep].tax;
      this.form[this.rep].fee_gross = this.grossSumFee;

      this.update(this.repository, this.id)
        .then(() => {
          // // pivot insurance_ancillary/insurer_participant
          let payload = { insurer_participant: {} };
          this.insurerParticipants.forEach((e) => {
            // payload.insurer_participant[this.id] = {
            payload.insurer_participant[e.id] =
              this.insurerParticipantsForm[e.id];
            // set the gross because was computed
            // se tasse e netto sono a 0 impostare il gross con la computed 'insPrtcGrss', altrimenti impostare il gross con il 'gross' del payload.
            payload.insurer_participant[e.id].gross =
              payload.insurer_participant[e.id].tax &&
              payload.insurer_participant[e.id].net
                ? this.insPrtcGrss(e.id)
                : payload.insurer_participant[e.id].gross;
          });
          /* {
              share: e.pivot.share || 0,
              net: e.pivot.net || 0,
              tax: e.pivot.tax || 0,
              gross: (e.pivot.net || 0) + (e.pivot.tax || 0),
              participant_prov_purchase: e.pivot.participant_prov_purchase || 0,
              participant_prov_take: e.pivot.participant_prov_take || 0,
            }; */

          /* let relation = "insurer_participant";
          const Repo = RepositoryFactory.get(this.repository);
          Repo.pivot_update(this.id, relation, payload) */
          const url = `insurance_ancillaries/${this.id}/pivot/insurer_participant`;
          client
            .get(url, {})
            .then(() => {
              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: Titolo Modificato`,
              });
              this.shortcut(
                "module.TIT.TINS",
                null,
                null,
                "filterInsuranceAncillaries"
              );
              this.isLoading = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: errMsg,
              });
              this.isLoading = false;
            });

          // this.$showSnackbar({
          //   preset: "success",
          //   text: `Azione Completata: Titolo Modificato`,
          // });
          // this.shortcut(
          //   "module.TIT.TINS",
          //   null,
          //   null,
          //   "filterInsuranceAncillaries"
          // );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
      // .finally(() => {
      //   this.isLoading = false;
      // });
    },
    handlePolicyModalInput(value) {
      this.form.quick_value = value.id;
      this.form[this.rep].insurance_policy_id = value.id;
      this.form.inpt_label = value.number;
      this.insurance_policy_data = value;
      this.$bvModal.hide("quickSearchPolicyModal");
    },
    openQuickSearchPolicy() {
      this.form.quick_value = null;
      this.$bvModal.show("quickSearchPolicyModal");
    },
    setStatusAncillary() {
      switch (this.form[this.rep].status_ancillary) {
        //Da incassare
        case 0:
          this.beForm[this.rep].status_ancillary.options = this.beForm[
            this.rep
          ].status_ancillary.options.filter((status_ancillary) =>
            [3, 2, 0].includes(status_ancillary.value)
          );
          break;
        //Incassato
        case 1:
          this.disabled = true;
          break;
        //Stornato
        case 2:
          this.beForm[this.rep].status_ancillary.options = this.beForm[
            this.rep
          ].status_ancillary.options.filter((status_ancillary) =>
            [2, 0].includes(status_ancillary.value)
          );
          break;
        //Annullato Manulamente
        case 3:
          this.beForm[this.rep].status_ancillary.options = this.beForm[
            this.rep
          ].status_ancillary.options.filter((status_ancillary) =>
            [3, 0].includes(status_ancillary.value)
          );
          break;
        //Da Validare
        case 7:
          this.beForm[this.rep].status_ancillary.options = this.beForm[
            this.rep
          ].status_ancillary.options.filter((status_ancillary) =>
            [7].includes(status_ancillary.value)
          );
          break;
      }
    },
    setCoassForm() {
      this.insurerParticipants.forEach((e) => {
        this.$set(this.insurerParticipantsForm, e.id, {
          share: e.pivot.share,
          net: e.pivot.net,
          tax: e.pivot.tax,
          gross: e.pivot.gross,
          participant_prov_purchase: e.pivot.participant_prov_purchase,
          participant_prov_take: e.pivot.participant_prov_take,
        });
      });
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
    }),
  },
  created() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, this.id).then(() => {
      // const Repo = RepositoryFactory.get("insurance_policy");
      // let queryString = `byAttribute[id]=${
      //   this.beForm[this.rep].insurance_policy_id.value
      // }&byInsurerParticipant`;
      // this.setStatusAncillary();
      // Repo.index(queryString)
      //   .then((response) => {
      //     this.$set(this, "insurance_policy_data", response.data.data[0]);
      //     this.form.insurance_policy_id =
      //       this.beForm[this.rep].insurance_policy_id.value;
      //     this.form.inpt_label = this.insurance_policy_data.number;
      //     this.code_options = this.beForm[this.rep].code.options.filter(
      //       (el) => el.value === this.form[this.rep].code
      //     );
      //     // Fee
      //     if (this.insurance_policy_data?.insurer?.id) {
      //       const selectedInsurer = this.getInsurers().find(
      //         (e) => e.value == this.insurance_policy_data?.insurer?.id
      //       );
      //       if (selectedInsurer && selectedInsurer.use_fee === "N") {
      //         this.form[this.rep].fee_tax = 0.0;
      //         this.form[this.rep].fee_net = 0.0;
      //         this.insurerHasFee = false;
      //       }
      //       if (selectedInsurer && selectedInsurer.use_fee === "Y") {
      //         this.insurerHasFee = true;
      //       }
      //     }
      //     // Coass
      //     if (this.hasCoass) {
      //       this.$set(
      //         this,
      //         "insurerParticipants",
      //         this.insurance_policy_data.insurer_participants
      //       );
      //     }
      //     this.isLoading = false;
      //   })
      //   .catch((error) => {
      //     let errMsg = this.$getErrorMessage(error);
      //     this.$showSnackbar({
      //       preset: "error",
      //       text: `${errMsg}`,
      //     });
      //     this.isLoading = false;
      //   });
      // 1172: chiamare la show dei titoli invece che la index di polizze
      // aggiungere /relations/byInsurerParticipant/byInsurancePolicy
      const Repo = RepositoryFactory.get("insurance_ancillary");
      let queryString =
        "relations/byInsurerParticipant/byInsurancePolicy/byBookEntry";
      this.setStatusAncillary();
      Repo.show(this.id, queryString)
        .then((response) => {
          this.$set(this, "ancillaryData", response.data.data);
          this.$set(
            this,
            "insurance_policy_data",
            response.data.data.insurance_policy
          );
          this.form.insurance_policy_id =
            this.beForm[this.rep].insurance_policy_id.value;
          this.form.inpt_label = this.insurance_policy_data.number;
          this.code_options = this.beForm[this.rep].code.options.filter(
            (el) => el.value === this.form[this.rep].code
          );
          // Fee
          if (this.insurance_policy_data?.insurer?.id) {
            const selectedInsurer = this.getInsurers().find(
              (e) => e.value == this.insurance_policy_data?.insurer?.id
            );
            if (selectedInsurer && selectedInsurer.use_fee === "N") {
              this.form[this.rep].fee_tax = 0.0;
              this.form[this.rep].fee_net = 0.0;
              this.insurerHasFee = false;
            }
            if (selectedInsurer && selectedInsurer.use_fee === "Y") {
              this.insurerHasFee = true;
            }
          }
          // Coass
          if (this.isDelegaNostra) {
            this.$set(
              this,
              "insurerParticipants",
              // this.insurance_policy_data.insurer_participants
              response.data.data.insurer_participants
            );
            this.setCoassForm();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
      // .finally(() => {
      //   this.isLoading = false;
      // });
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
    ins_prtc() {
      return "insurer_participant";
    },
    agencyHasFee() {
      return this.$store.state.auth.use_fee === "Y" ? true : false;
    },
    // hasCoass() {
    //   return this.insurance_policy_data.insurer_participants?.length;
    // },
    isDelegaNostra() {
      let type = 0;
      return this.insurance_policy_data.sharing_type.value === type;
    },
    isImported() {
      // qui non ho il campo imported_at....
      return this.insurance_policy_data.insurer.importer_id ? true : false;
    },
    isDaIncassare() {
      // {value: 0, text: "Da incassare"}
      // return this.beForm[this.rep].status_ancillary.value == 0;
      return this.ancillaryData.status_ancillary.value == 0;
    },
    isDeposited() {
      return this.ancillaryData.book_entries.length ? true : false;
    },
    isPorQ() {
      return ["P", "Q"].includes(this.ancillaryData.code.value) ? true : false;
    },
    grossSumFee() {
      return this.form[this.rep].fee_net + this.form[this.rep].fee_tax;
    },
    grossPremiumSum: {
      get() {
        return toLocaleCurrency(
          this.form[this.rep].net + this.form[this.rep].tax
        );
      },
    },
    insPrtcGrss() {
      return (id) =>
        parseFloat(
          (
            parseFloat(this.insurerParticipantsForm[id].net) +
            parseFloat(this.insurerParticipantsForm[id].tax)
          ).toFixed(2)
        );
    },
  },
  mounted() {
    this.salesmen = this.getSalesmen();
  },
  watch: {
    "form.inpt_label": {
      deep: true,
      handler() {
        if (!this.form[this.rep].insurance_policy_id) {
          this.insurance_policy_data = null;
        }
      },
    },
  },
};
</script>
