<template>
  <b-modal
    dialog-class="quicksearch-policy-modal"
    id="quickSearchPolicyModal"
    size="xl"
    scrollable
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="file-text"></b-icon>
        <span>Cerca Polizze</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>
    <b-form @submit.prevent="onSearch(filterName)">
      <b-row>
        <div class="col-md-4">
          <base-input
            name="Polizza"
            type="text"
            label="Numero"
            v-model="filter.byAttribute.number"
            placeholder="Inserisci un numero polizza"
          />
        </div>
        <div class="col-md-4">
          <base-input
            name="Polizza"
            type="text"
            label="Nominativo contraente"
            v-model="filter.byRegistry.title"
            placeholder="Inserisci un nominativo contraente"
          />
        </div>
        <div class="col-md-4 align-self-center actions-wrapper">
          <b-button type="submit" variant="lisaweb" size="sm">Cerca</b-button>
          <b-button
            class="btn-reset"
            type="button"
            variant="lisaweb"
            size="sm"
            @click="onClearFilter(filterName)"
            >Reset</b-button
          >
        </div>
      </b-row>
    </b-form>
    <policies
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :filterOn="{
        byRelations: ['byBroker', 'byRegistry', 'byMandateCode'],
      }"
      noInnerWidth
      noActions
      includeRowSelector
      noInputPaginator
      @rowSelector="onModalSelector"
    >
    </policies>
  </b-modal>
</template>
<script>
import BaseInput from "@/components/form/BaseInput";
// import Policies from "@/components/tables/Policies";
import { mapActions } from "vuex";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { toLocaleDate } from "@/utils/dates";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      filter: this.initFilter(),
      filterName: "filterQuickSearchPolicy",
      repository: "insurance_policy",
      resource: "insurance_policies",
      tableRef: "QuickSearchPolicyTableRef",
      fields: [
        {
          key: "rowSelector",
          label: "Azioni",
          thStyle: {
            width: "20px",
          },
        },
        {
          key: "number",
          value: "number",
          label: this.getDictionary("number", "insurance_policy"),
          sortable: true,
          sortkey: "byAttribute.number",
        },
        {
          key: "insurer.title",
          value: "insurer.title",
          label: this.getDictionary("title", "insurer"),
          sortable: true,
          sortKey: "byInsurer.title",
        },
        {
          key: "contraente",
          label: this.getDictionary("contractor"),
          sortable: true,
          sortKey: "byRegistry.title",
        },
        {
          key: "insurance_risk",
          value: "insurance_risk.title",
          label: this.getDictionary("insurance_risk"),
        },
        {
          key: "effective_at",
          label: this.getDictionary("effective_at", "insurance_policy"),
          value: "effective_at",
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          sortKey: "effective_at",
        },
        {
          key: "expired_at",
          label: this.getDictionary("expired_at", "insurance_policy"),
          value: "expired_at",
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          sortKey: "expired_at",
        },
      ],
    };
  },
  components: {
    Policies: () => import("@/components/tables/Policies"),
    BaseInput,
  },
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          number: null,
        },
        byRegistry: {
          title: null,
        },
      };
      return init;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch().then(() => {
        this.removeFilterByName(name);
      });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      // this.onSearch(name);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onModalSelector(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId !== "quickSearchPolicyModal") return;
      this.onClearFilter(this.filterName);
    });
  },
};
</script>

<style scoped>
.actions-wrapper {
  margin-top: 5px;
}
</style>
