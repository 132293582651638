<template>
  <b-modal
    dialog-class="quicksearch-group-modal"
    id="quickSearchGroupModal"
    size="lg"
    scrollable
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="people-fill"></b-icon>
        <span>Cerca Gruppi</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>
    <b-form @submit.prevent="onSearch(filterName)">
      <div class="mt-2">
        <b-card class="filter">
          <b-row>
            <div class="col-md-4">
              <base-input
                name="group"
                v-model="filter.byCustom.code.value"
                type="text"
                label="Codice Gruppo"
                placeholder="Inserisci un gruppo"
              />
            </div>
            <div class="col-md-4">
              <base-input
                name="titolo"
                v-model="filter.byCustom.title.value"
                type="text"
                label="Descrizione"
                placeholder="Inserisci un titolo"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button type="submit" variant="lisaweb" size="sm"
                >Cerca
              </b-button>
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset
              </b-button>
            </div>
          </b-row>
        </b-card>
      </div>
    </b-form>
    <table-group
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        byRelations: ['byRegistry'],
      }"
      :ref="tableRef"
      noActions
      includeRowSelector
      @rowSelector="onModalSelector"
    ></table-group>
  </b-modal>
</template>
<script>
import BaseInput from "@/components/form/BaseInput";
import TableGroup from "@/components/tables/InsurancePaymentsRegistryGroups";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { mapActions } from "vuex";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      isLoading: false,
      repository: "registry_group",
      resource: "registry_groups",
      filterName: "filterRegistryGroup",
      filter: this.initFilter(),
      items: [],
      tableRef: "RegistryGroupTableRef",
      fields: [
        {
          key: "rowSelector",
          label: "Azioni",
          thStyle: {
            width: "5px",
          },
        },
        {
          key: "code",
          value: "code",
          label: this.getDictionary("code", "registry_group"),
          sortable: true,
          sortKey: "byAttribute.code",
        },
        {
          key: "title",
          value: "title",
          label: this.getDictionary("title", "registry_group"),
          sortable: true,
          sortKey: "byAttribute.title",
        },
        {
          key: "coordinator",
          value: "coordinator",
          label: this.getDictionary("is_coordinator", "registry_group"),
          sortable: false,
        },
        {
          key: "anagrafica",
          value: (item) =>
            item.registries
              ? item.registries
                  .map((e) => {
                    return e.status_registry.value === 1
                      ? e.attributables.CNAM
                      : e.attributables.NAME + " " + e.attributables.SURN;
                  })
                  .join(",")
              : "",
          label: this.getDictionary("registry"),
          sortable: false,
        },
      ],
    };
  },
  components: {
    TableGroup,
    BaseInput,
  },
  methods: {
    onModalSelector(group) {
      this.$emit("input", group);
    },
    initFilter() {
      let init = {
        byCustom: {
          code: {
            value: null,
            likewise: 3,
            filter: "byAttribute",
          },
          title: {
            value: null,
            likewise: 3,
            filter: "byAttribute",
          },
        },
        // byAttribute: {
        //   code: null,
        //   title: null,
        // },
      };
      return init;
    },
    customFilterName(key, prefix = "attributables") {
      return `${prefix}.${key}`;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
  props: {
    activeGroup: { type: Number },
  },
};
</script>

<style scoped>
.subject {
  font-weight: bold;
  font-size: 1.5em;
}
</style>
